.App {
  text-align: center;
  font-family: sans-serif;
  height: 100vh;
  width: 100vw;
  background:radial-gradient(circle, rgba(255,194,0,1) 38%, rgba(254,109,1,1) 100%)

}


